<template>
    <section class="content pl-2 pr-2">
        <div class="row mb-4">
            <div class="col-sm-6" v-if="currentPackage">
                <div class="font-bold">Current Package</div>
                <div>Name: {{ currentPackage.name }}</div>
                <div>description: {{ currentPackage.name }}</div>
                <div>Price: ${{ pricingType === '1'? currentPackage.monthly_price: currentPackage.yearly_price }}</div>
                <div v-if="remaining_days">Remaining Days: {{ remaining_days }}</div>
                <div v-if="remaining_credits">Remaining Credits: ${{ remaining_credits }}</div>
                <div v-if="billingWalletBalance">Wallet Balance: ${{ billingWalletBalance }}</div>
            </div>
            <div class="col-sm-6" v-if="selectedPackage">
                <div class="font-bold">Selected Package</div>
                <div>Name: {{ selectedPackage.name }}</div>
                <div>description: {{ selectedPackage.name }}</div>
                <div>Price: ${{ pricingType === '1'? selectedPackage.monthly_price: selectedPackage.yearly_price }}</div>
                <div>Period: {{ pricingType === '1'? 30: 365 }} days</div>
            </div>
        </div>
        <div>
            <span v-if="amount > 0">Pay <span class="font-bold">${{ amount }}</span> more to subscribe (prorating current subscription).</span>
            <span v-else-if="amount < 0">You will have <span class="font-bold">${{ Math.abs(amount_after_subscription) }}</span> in your wallet after subscription (prorating current subscription).</span>
            <span v-else>You do not need to make any payment to subscribe.</span>

            <div class="text-center">
                <button type="button" class="btn btn-default btn-primary mt-3" @click="createBillingInvoice()">Complete Subscription <i class="fas fa-chevron-right"></i></button>
            </div>
        </div>
    </section>
</template>

<script>
import Vue from 'vue';
import {BillingService} from "@/services/billing-service";
let billingService = new BillingService();
import {UserService} from "@/services/user-service";
let userService = new UserService();
export default {
    name: "GenerateBillingInvoice",
    data: function() {
        return {
            currentPackage: null,
            selectedPackage: null,
            amount: null,
            amount_after_subscription: null,
            perDayPrice: null,
            remaining_days: null,
            remaining_credits: null,
            billingWalletBalance: null,
        }
    },
    props:{
        selectedPackId:{
            type: Number,
            default: () => null
        },
        pricingType:{
            type: String,
            default: () => null
        },
    },
    computed: {

    },
    components: {},
    methods:{
        loadSavedData: async function () {
            let loader = this.$loading.show();
            let response = await billingService.getBillingInvoiceCalculation(this.selectedPackId, this.pricingType);
            if (response.isSuccess) {
                this.currentPackage = response.currentPackage;
                this.selectedPackage = response.selectedPackage;
                this.perDayPrice = response.perDayPrice;
                this.amount = response.amount;
                this.amount_after_subscription = response.amount_after_subscription;
                this.remaining_days = response.remaining_days;
                this.remaining_credits = response.remaining_credits;
                this.billingWalletBalance = response.billingWalletBalance;
            } else {
                Vue.$toast.open({message: response.message, type: 'error'});
            }
            loader.hide();
        },
        createBillingInvoice: async function () {
            let loader = this.$loading.show();
            let response = await billingService.saveBillingInvoice(this.selectedPackId, this.pricingType);
            if (response.isSuccess) {
                let userInitResponse = await userService.userInit();
                if(userInitResponse.isSuccess){
                    await this.$router.push({name: 'App.Billing'});
                }
            } else {
                Vue.$toast.open({message: response.message, type: 'error'});
            }
            loader.hide();
        },
    },
    async mounted() {
        await this.loadSavedData();
    }
}
</script>

<style scoped>

</style>