//package
import Vue from "vue";

export function getPackagePermissionByKey(key, current_value, comparison) {
    let user = Vue.prototype.$store.getters['auth/user'];
    if (user.company.package.permissions) {
        //console.log(user.company.package.permissions);
        let permissions = JSON.parse(user.company.package.permissions);
        if(current_value){
            if(comparison == '>') {
                return permissions[key] > current_value;
            }else if (comparison == '<'){
                return permissions[key] < current_value;
            }else if(comparison == '>=') {
                return permissions[key] >= current_value;
            }else if (comparison == '<='){
                return permissions[key] <= current_value;
            }else{
                return false;
            }
        }else return permissions[key] === "1";
    } else {
        return false;
    }
}
export function getUserCompanyPackageId() {
    let user = Vue.prototype.$store.getters['auth/user'];
    return user.company.package_id;
}