<template>
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content px-lg-5">
            <div class="row px-lg-5">
                <div class="col-sm-4" v-for="(pack, i) in packages" :key="i">
                    <div class="card" :class="{'bg-primary py-lg-3': pack.featured, 'mt-lg-2': !pack.featured}">
                        <div class="card-body" :class="{'text-gray': !pack.featured}">
                            <div class="ribbon-2" v-if="pack.featured">Most Popular</div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="font-bold font-size-18">{{ pack.name}}</div>
                                </div>
                                <div class="col-4 text-right" v-if="pack.featured">

<!--                                    <span class="badge badge-light">Recommended</span>-->
                                </div>
                            </div>
                            <p class="card-text">{{ pack.description }}</p>
                            <div class="row mb-3">
                                <div class="col-4">
                                    <div class="font-bold font-size-35">${{ pricingType === '1'? pack.monthly_price: pack.yearly_price }}</div>
                                    <div>Per {{ pricingType === '1'? 'month': 'year' }}</div>
                                </div>
                                <div class="col-8 pt-3 text-right">
                                    <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                        <label class="btn btn-sm" :class="{'btn-outline-light': pack.featured, 'btn-outline-secondary': !pack.featured, 'active': pricingType === '1'}">
                                            <input type="radio" v-model="pricingType" value="1" autocomplete="off" v-on:change="changePricingType"> Monthly
                                        </label>
                                        <label class="btn btn-sm" :class="{'btn-outline-light': pack.featured, 'btn-outline-secondary': !pack.featured, 'active': pricingType === '2'}">
                                            <input type="radio" v-model="pricingType" value="2" autocomplete="off" v-on:change="changePricingType"> Yearly
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <hr />

                            <div class="mt-4 d-flex justify-content-between">
                                <span>User seats:</span>
                                <span>{{ getPackageUsers(pack) }}</span>
                            </div>

                            <div class="mt-2 d-flex justify-content-between">
                                <span>Stock Transfer:</span>
                                <span class="text-right">
                                    <i class="fas fa-check" v-if="getPackageStockTransfer(pack)"></i>
                                    <i class="far fa-times-circle" v-else></i>
                                </span>
                            </div>

                            <div class="mt-2">Reports</div>
                            <div class="mt-2 ml-3 d-flex justify-content-between" v-for="(report, i) in reports" :key="i">
                                <span>{{ trimReportName(report.key) }}</span>
                                <span class="text-right">
                                    <i class="fas fa-check" v-if="isReportIncluded(pack, report.key)"></i>
                                    <i class="far fa-times-circle" v-else></i>
                                </span>
                            </div>

                            <hr />
                            <div class="text-center">
                                <button type="button" class="btn btn-default mb-3" disabled v-if="getUserCompanyPackageId() === pack.id">Current Package</button>
<!--                                <div class="font-size-12 text-left font-italic" v-else-if="pack.monthly_price === 0 && pack.yearly_price === 0 && pack.id !== getUserCompanyPackageId()">You cannot downgrade to this package while there is an active paid subscription. You will be automatically downgraded to this package once your current subscription expires.</div>
                                <button type="button" class="btn mb-3" :class="{'btn-outline-light': pack.featured, 'btn-outline-primary': !pack.featured}" v-else @click="showSubscriptionModal(pack)">Subscribe</button>-->
                                <button type="button" class="btn mb-3" :class="{'btn-outline-light': pack.featured, 'btn-outline-primary': !pack.featured}" v-else>Coming Soon</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <ModalGeneral :title="subscriptionModal.title" size="modal-lg" :showModal="subscriptionModal.showModal" :hideModal="hideSubscriptionModal" v-if="subscriptionModal.showModal">
            <div slot="modal-body">
                <generate-billing-invoice :selected-pack-id="subscriptionModal.selectedPackId" :pricing-type="pricingType"></generate-billing-invoice>
            </div>
        </ModalGeneral>
    </div>
</template>

<script>
import BreadCrumb from "@/components/app/common/BreadCrumb";
import Vue from "vue";
import {UserService} from "@/services/user-service";
import {kvObjectToKvArray} from "@/helpers/commonHelper";
import {humanize} from "@/helpers/stringHelper";
import {getUserCompanyPackageId} from "@/helpers/packageHelper";
import ModalGeneral from "@/components/app/dialog/ModalGeneral";
import GenerateBillingInvoice from "@/components/app/billing/GenerateBillingInvoice";

let userService = new UserService();
export default {
    name: "Packages_view",
    components: {GenerateBillingInvoice, ModalGeneral, BreadCrumb},
    data: function(){
        return {
            pageName: 'Packages',
            segments: [
                { link: false, text: 'Packages' },
            ],
            packages: [],
            reports: kvObjectToKvArray(this.$globalSettings.permissions.report),
            price: 0,
            pricingType: '1',
            subscriptionModal:{
                title: 'New Subscription',
                showModal: false,
                selectedPackId: null,
            },
        }
    },
    methods:{
        humanize, getUserCompanyPackageId,
        loadPackages: async function () {
            let loader = this.$loading.show({container: this.$refs.formContainer});
            let response = await userService.getPackages();
            if (response.isSuccess) {
                this.packages = response.packages;
            } else {
                Vue.$toast.open({message: response.message, type: 'error'});
            }
            loader.hide();
        },
        getPackageUsers(pack) {
            let permissions = JSON.parse(pack.permissions);
            return permissions? permissions.users: 0;
        },
        getPackageStockTransfer(pack) {
            let permissions = JSON.parse(pack.permissions);
            if(permissions){
                return permissions.stockTransfer === "1" || permissions.stockTransfer === 1;
            }
            return false;
        },
        trimReportName(reportMame){
            return humanize(reportMame).replace('Report ', '');
        },
        isReportIncluded(pack, reportKey){
            let permissions = JSON.parse(pack.permissions);
            for (let prop in permissions) {
                if (Object.prototype.hasOwnProperty.call(permissions, prop)) {
                    if(prop === reportKey){
                        return permissions[prop] === "1" || permissions[prop] === 1;
                    }
                }
            }
            return false;
        },
        changePricingType(){

        },
        showSubscriptionModal(data){
            this.subscriptionModal.selectedPackId = data.id;
            this.subscriptionModal.showModal = true;
        },
        hideSubscriptionModal(){
            this.subscriptionModal.showModal = false;
        },
    },
    async mounted() {
        await this.loadPackages();
    }
}
</script>

<style scoped>
    .ribbon-1 {
        position: fixed;
        background: #08769b;
        box-shadow: 0 0 0 999px #08769b;
        clip-path: inset(0 -100%);
    }
    .left {
        inset: 0 auto auto 0;
        transform-origin: 100% 0;
        transform: translate(-29.3%) rotate(-45deg);
    }
    .right {
        inset: 0 0 auto auto;
        transform-origin: 0 0;
        transform: translate(29.3%) rotate(45deg);
    }

    .ribbon-2 {
        --f: 10px; /* control the folded part*/
        --r: 15px; /* control the ribbon shape */
        --t: 10px; /* the top offset */

        position: absolute;
        inset: var(--t) calc(-1*var(--f)) auto auto;
        padding: 5px 10px calc(5px + var(--f)) calc(15px + var(--r));
        clip-path:
                polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
                calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
                var(--r) calc(50% - var(--f)/2));
        background: #343A40;
        box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
        color: #ffffff;
    }
</style>