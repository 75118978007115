import axios from "axios";
import Vue from "vue";
import {processHttpErrors} from "@/helpers/commonHelper";
//import dayjs from "dayjs";

export class BillingService{
    //formDataPayment = {};
    constructor() {
        //this.formDataPayment = {};
    }

    async getBillingInvoice(id){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.billing.billingInvoice, {
            id: id
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, billingInvoice: response.data.billingInvoice, asOldBillingWalletTransactions: response.data.asOldBillingWalletTransactions, asNewBillingWalletTransactions: response.data.asNewBillingWalletTransactions};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', billingInvoice: null, asOldBillingWalletTransactions: [], asNewBillingWalletTransactions: []};
            });
    }
    async getBillingInvoiceCalculation(id, pricingType){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.billing.billingInvoiceCalculation, {
            selectedPackId: id,
            pricingType: pricingType
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, currentPackage: response.data.currentPackage, selectedPackage: response.data.selectedPackage, amount: response.data.amount, amount_after_subscription: response.data.amount_after_subscription, perDayPrice: response.data.perDayPrice, subscribed_for: response.data.subscribed_for, remaining_days: response.data.remaining_days, remaining_credits: response.data.remaining_credits, billingWalletBalance: response.data.billingWalletBalance};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', currentPackage: null, selectedPackage: null, amount: 0, amount_after_subscription: 0, perDayPrice: 0, remaining_days: 0, remaining_credits: 0, billingWalletBalance: 0};
            });
    }

    async saveBillingInvoice(id, pricingType){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.billing.billingInvoiceSave, {
            selectedPackId: id,
            pricingType: pricingType
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, billingInvoice: response.data.billingInvoice, billingSubscription: response.data.billingSubscription};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', billingInvoice: null, billingSubscription: null};
            });
    }

    async deleteInvoice(id){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.billing.billingInvoiceDelete, {
            id: id
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!'};
            });
    }

    async getBillingWalletBalance(){
        return await axios.get(Vue.prototype.$globalSettings.api.endpoints.billing.billingWalletBalance)
            .then((response) => {
                return {isSuccess: true, message: '', billingWalletBalance: response.data.billingWalletBalance};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', billingWalletBalance: 0};
            });
    }
}